// ENTITY fr.hiji.metier.certif.dto.front.modality.interview.result.ModalityInterviewResultDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityInterviewTarget } from './ModalityInterviewTarget';
import { ModalityInterviewQuickQuestion } from './ModalityInterviewQuickQuestion';
import { ModalityInterviewExamOG } from './ModalityInterviewExamOG';
import { CertificationExam } from './CertificationExam';
import { ModalityInterviewExam } from './ModalityInterviewExam';
import { ModalityDTO } from './ModalityDTO';
import { Criteria } from './Criteria';
import { ObservableGestureDTO } from './ObservableGestureDTO';
import { ModalityInterviewExamSkillAvg } from './ModalityInterviewExamSkillAvg';
import { ModalityInterview } from './ModalityInterview';

export class ModalityInterviewResultDTO {

  modality: ModalityDTO | null = null;
  exam: CertificationExam | null = null;
  modalityInterviewExam: ModalityInterviewExam | null = null;
  modalityInterview: ModalityInterview | null = null;
  criterias: Criteria[] | null = [];
  observableGestures: ObservableGestureDTO[] | null = [];
  answers: ModalityInterviewExamOG[] | null = [];
  quickQuestions: ModalityInterviewQuickQuestion[] | null = [];
  averageSkills: ModalityInterviewExamSkillAvg[] | null = [];
  targetSkills: ModalityInterviewTarget[] | null = [];

  constructor(init?:Partial<ModalityInterviewResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.interview.result.ModalityInterviewResultDTO'] = ModalityInterviewResultDTO;

reverseMapping[ModalityInterviewResultDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.interview.result.ModalityInterviewResultDTO';

fields['fr.hiji.metier.certif.dto.front.modality.interview.result.ModalityInterviewResultDTO']  = {
    modality: 'fr.hiji.metier.certif.dto.front.modality.ModalityDTO',
    exam: 'fr.hiji.metier.certif.entities.session.CertificationExam',
    modalityInterviewExam: 'fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExam',
    modalityInterview: 'fr.hiji.metier.certif.entities.modality.interview.ModalityInterview',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservableGestureDTO>',
    answers: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamOG>',
    quickQuestions: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewQuickQuestion>',
    averageSkills: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamSkillAvg>',
    targetSkills: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewTarget>'
};
