// ENTITY fr.hiji.metier.conf.dto.submodules.ModulePDIDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModulePDIDTO {

  modulePDI: boolean | null = false;

  constructor(init?:Partial<ModulePDIDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModulePDIDTO'] = ModulePDIDTO;

reverseMapping[ModulePDIDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModulePDIDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModulePDIDTO']  = {
    modulePDI: 'java.lang.Boolean'
};
