// ENTITY fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionModalityBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CertificationSessionModality } from './CertificationSessionModality';
import { ModalityType } from './ModalityType';

export class CertificationSessionModalityBODTO {

  certificationSessionModality: CertificationSessionModality | null = null;
  code: string | null = '';
  name: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;
  type: ModalityType | null = null;
  needAccessGrantFromEvaluator: boolean | null = false;

  constructor(init?:Partial<CertificationSessionModalityBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionModalityBODTO'] = CertificationSessionModalityBODTO;

reverseMapping[CertificationSessionModalityBODTO.name] = 'fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionModalityBODTO';

fields['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionModalityBODTO']  = {
    certificationSessionModality: 'fr.hiji.metier.certif.entities.session.CertificationSessionModality',
    code: 'java.lang.String',
    name: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean',
    type: 'fr.hiji.metier.certif.entities.modality.ModalityType',
    needAccessGrantFromEvaluator: 'java.lang.Boolean'
};
