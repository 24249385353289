// ENTITY fr.hiji.metier.home.dto.front.FormationSessionLiteDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationSessionLiteDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  colorCodeFormation: string | null = '';
  idFormationSession: number | null = 0;
  formationSessionName: string | null = '';
  colorCodeFormationSession: string | null = '';
  minDate: Date | null = null;
  maxDate: Date | null = null;
  trainer: boolean | null = false;
  formationImageExtension: string | null = '';

  constructor(init?:Partial<FormationSessionLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.FormationSessionLiteDTO'] = FormationSessionLiteDTO;

reverseMapping[FormationSessionLiteDTO.name] = 'fr.hiji.metier.home.dto.front.FormationSessionLiteDTO';

fields['fr.hiji.metier.home.dto.front.FormationSessionLiteDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    colorCodeFormation: 'java.lang.String',
    idFormationSession: 'java.lang.Integer',
    formationSessionName: 'java.lang.String',
    colorCodeFormationSession: 'java.lang.String',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate',
    trainer: 'java.lang.Boolean',
    formationImageExtension: 'java.lang.String'
};
