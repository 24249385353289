// fr.hiji.metier.referential.services.back.ObservableGestureBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { ObservableGestureLiteBODTO } from '../entities/ObservableGestureLiteBODTO';
import { ObservableGestureBODTO } from '../entities/ObservableGestureBODTO';

@Injectable({
  providedIn: 'root',
})
export class ObservableGestureBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getObservableGestures(filter: GenericFilter): RpcRequestBuilder<ListWithCount<ObservableGestureBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservableGestureBOService.getObservableGestures';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  observableGestureNameAlreadyExists(name: MultilingualString, idObservableGesture: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservableGestureBOService.observableGestureNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  observableGestureBenchmarkCodeAlreadyExists(ogbodto: ObservableGestureBODTO): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservableGestureBOService.observableGestureBenchmarkCodeAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(ogbodto, 'fr.hiji.metier.referential.dto.ObservableGestureBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getObservableGesture(idObservableGesturel: number): RpcRequestBuilder<ObservableGestureBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservableGestureBOService.getObservableGesture';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idObservableGesturel, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.ObservableGestureBODTO');
  }

  saveObservableGesture(ogbodto: ObservableGestureBODTO): RpcRequestBuilder<ObservableGestureBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservableGestureBOService.saveObservableGesture';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(ogbodto, 'fr.hiji.metier.referential.dto.ObservableGestureBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.ObservableGestureBODTO');
  }

  getSimilarObservableGestures(text: string): RpcRequestBuilder<ObservableGestureLiteBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservableGestureBOService.getSimilarObservableGestures';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(text, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getSimilarObservableGesturesWithLang(text: string, language: string): RpcRequestBuilder<ObservableGestureLiteBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ObservableGestureBOService.getSimilarObservableGesturesWithLang';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(text, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(language, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
