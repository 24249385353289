// ENTITY fr.hiji.metier.visualize.dto.front.VizInquiryAnswerDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class VizInquiryAnswerDTO {

  idInquiry: number | null = 0;
  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';
  name: MultilingualString | null = new MultilingualString();
  answerDate: Date | null = null;
  draft: boolean | null = false;
  assessor: boolean | null = false;

  constructor(init?:Partial<VizInquiryAnswerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.visualize.dto.front.VizInquiryAnswerDTO'] = VizInquiryAnswerDTO;

reverseMapping[VizInquiryAnswerDTO.name] = 'fr.hiji.metier.visualize.dto.front.VizInquiryAnswerDTO';

fields['fr.hiji.metier.visualize.dto.front.VizInquiryAnswerDTO']  = {
    idInquiry: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    name: 'com.ic2.sc.MultilingualString',
    answerDate: 'java.time.LocalDate',
    draft: 'java.lang.Boolean',
    assessor: 'java.lang.Boolean'
};
