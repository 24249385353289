// fr.hiji.metier.conf.services.back.ManifestConfigBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class ManifestConfigBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getConfigFile(): RpcRequestBuilder<object> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ManifestConfigBOService.getConfigFile';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.json.JSONObject');
  }

  saveConfigManifest(data: object): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ManifestConfigBOService.saveConfigManifest';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(data, 'com.ic2.json.JSONObject', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
