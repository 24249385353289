// ENTITY fr.hiji.metier.mif.dto.front.event.FormationEventAnimationOGSDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationEventAnimationType } from './FormationEventAnimationType';
import { FormationEventSatisfactionOgsOrderDTO } from './FormationEventSatisfactionOgsOrderDTO';

export class FormationEventAnimationOGSDTO {

  idFormationEventAnimation: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  type: FormationEventAnimationType | null = null;
  idOgs: FormationEventSatisfactionOgsOrderDTO[] | null = [];
  order: number | null = 0;

  constructor(init?:Partial<FormationEventAnimationOGSDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.event.FormationEventAnimationOGSDTO'] = FormationEventAnimationOGSDTO;

reverseMapping[FormationEventAnimationOGSDTO.name] = 'fr.hiji.metier.mif.dto.front.event.FormationEventAnimationOGSDTO';

fields['fr.hiji.metier.mif.dto.front.event.FormationEventAnimationOGSDTO']  = {
    idFormationEventAnimation: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    type: 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationType',
    idOgs: 'java.util.List<fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOgsOrderDTO>',
    order: 'java.lang.Integer'
};
