// ENTITY fr.hiji.metier.certif.dto.front.result.CertificationExamResultDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityQuizTarget } from './ModalityQuizTarget';
import { ModalityInterviewTarget } from './ModalityInterviewTarget';
import { ModalityRoleplayAnswerSkillAvg } from './ModalityRoleplayAnswerSkillAvg';
import { ModalityRoleplay } from './ModalityRoleplay';
import { ModalityInterviewExamSkillAvg } from './ModalityInterviewExamSkillAvg';
import { ModalityQuizExam } from './ModalityQuizExam';
import { ModalityQuiz } from './ModalityQuiz';
import { CertificationExam } from './CertificationExam';
import { ModalityRoleplayExam } from './ModalityRoleplayExam';
import { ModalityInterviewExam } from './ModalityInterviewExam';
import { ModalityRoleplayTarget } from './ModalityRoleplayTarget';
import { ModalityLiteDTO } from './ModalityLiteDTO';
import { ModalityQuizExamSkillAvg } from './ModalityQuizExamSkillAvg';
import { ModalityInterview } from './ModalityInterview';

export class CertificationExamResultDTO {

  modality: ModalityLiteDTO | null = null;
  exam: CertificationExam | null = null;
  idCertificationSession: number | null = 0;
  sessionName: string | null = '';
  modalityQuiz: ModalityQuiz | null = null;
  quizExam: ModalityQuizExam | null = null;
  quizSkillAvg: ModalityQuizExamSkillAvg[] | null = [];
  quizSkillTarget: ModalityQuizTarget[] | null = [];
  modalityRoleplay: ModalityRoleplay | null = null;
  roleplayExam: ModalityRoleplayExam | null = null;
  roleplaySkillAvgAutoObs: ModalityRoleplayAnswerSkillAvg[] | null = [];
  roleplaySkillAvgObs: ModalityRoleplayAnswerSkillAvg[] | null = [];
  roleplaySkillTarget: ModalityRoleplayTarget[] | null = [];
  modalityInterview: ModalityInterview | null = null;
  interviewExam: ModalityInterviewExam | null = null;
  interviewSkillTarget: ModalityInterviewTarget[] | null = [];
  interviewSkillAvg: ModalityInterviewExamSkillAvg[] | null = [];

  constructor(init?:Partial<CertificationExamResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.result.CertificationExamResultDTO'] = CertificationExamResultDTO;

reverseMapping[CertificationExamResultDTO.name] = 'fr.hiji.metier.certif.dto.front.result.CertificationExamResultDTO';

fields['fr.hiji.metier.certif.dto.front.result.CertificationExamResultDTO']  = {
    modality: 'fr.hiji.metier.certif.dto.front.modality.ModalityLiteDTO',
    exam: 'fr.hiji.metier.certif.entities.session.CertificationExam',
    idCertificationSession: 'java.lang.Integer',
    sessionName: 'java.lang.String',
    modalityQuiz: 'fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz',
    quizExam: 'fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExam',
    quizSkillAvg: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExamSkillAvg>',
    quizSkillTarget: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizTarget>',
    modalityRoleplay: 'fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplay',
    roleplayExam: 'fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayExam',
    roleplaySkillAvgAutoObs: 'java.util.List<fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerSkillAvg>',
    roleplaySkillAvgObs: 'java.util.List<fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerSkillAvg>',
    roleplaySkillTarget: 'java.util.List<fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplayTarget>',
    modalityInterview: 'fr.hiji.metier.certif.entities.modality.interview.ModalityInterview',
    interviewExam: 'fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExam',
    interviewSkillTarget: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewTarget>',
    interviewSkillAvg: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamSkillAvg>'
};
