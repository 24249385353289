// ENTITY fr.hiji.metier.referential.entities.quiz.QuestionQCM generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class QuestionQCM {

  idQuestion: number | null = 0;
  allOrNothing: boolean | null = false;
  showWeighting: boolean | null = false;
  showNumberOfAnswersHint: boolean | null = false;
  points: number | null = 0;

  constructor(init?:Partial<QuestionQCM>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.quiz.QuestionQCM'] = QuestionQCM;

reverseMapping[QuestionQCM.name] = 'fr.hiji.metier.referential.entities.quiz.QuestionQCM';

fields['fr.hiji.metier.referential.entities.quiz.QuestionQCM']  = {
    idQuestion: 'java.lang.Integer',
    allOrNothing: 'java.lang.Boolean',
    showWeighting: 'java.lang.Boolean',
    showNumberOfAnswersHint: 'java.lang.Boolean',
    points: 'java.lang.Double'
};
