// ENTITY fr.hiji.metier.mif.dto.back.formation.FormationTrainerBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationTrainer } from './FormationTrainer';

export class FormationTrainerBODTO {

  formationTrainer: FormationTrainer | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  del: boolean | null = false;

  constructor(init?:Partial<FormationTrainerBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formation.FormationTrainerBODTO'] = FormationTrainerBODTO;

reverseMapping[FormationTrainerBODTO.name] = 'fr.hiji.metier.mif.dto.back.formation.FormationTrainerBODTO';

fields['fr.hiji.metier.mif.dto.back.formation.FormationTrainerBODTO']  = {
    formationTrainer: 'fr.hiji.metier.mif.entities.formation.FormationTrainer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    del: 'java.lang.Boolean'
};
