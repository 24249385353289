// ENTITY fr.hiji.metier.reseau.dto.FonctionLiteDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FonctionLiteDTO {

  idFonction: number | null = 0;
  name: string | null = '';

  constructor(init?:Partial<FonctionLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.dto.FonctionLiteDTO'] = FonctionLiteDTO;

reverseMapping[FonctionLiteDTO.name] = 'fr.hiji.metier.reseau.dto.FonctionLiteDTO';

fields['fr.hiji.metier.reseau.dto.FonctionLiteDTO']  = {
    idFonction: 'java.lang.Integer',
    name: 'java.lang.String'
};
