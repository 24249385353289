// ENTITY fr.hiji.metier.mif.dto.back.stats.FormationSkillStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OGStatsPerFormationObservationTypeBODTO } from './OGStatsPerFormationObservationTypeBODTO';

export class FormationSkillStatsBODTO {

  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  showInRadar: boolean | null = false;
  avg: number | null = null;
  obsTypes: OGStatsPerFormationObservationTypeBODTO[] | null = [];

  constructor(init?:Partial<FormationSkillStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.FormationSkillStatsBODTO'] = FormationSkillStatsBODTO;

reverseMapping[FormationSkillStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.FormationSkillStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.FormationSkillStatsBODTO']  = {
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    showInRadar: 'java.lang.Boolean',
    avg: 'java.lang.Double',
    obsTypes: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.acquisition.OGStatsPerFormationObservationTypeBODTO>'
};
