// ENTITY fr.hiji.metier.reporting.entities.survey.SurveyReportOrganisation generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyReportOrganisation {

  idSurveyReport: number | null = 0;
  idOrganisation: number | null = 0;

  constructor(init?:Partial<SurveyReportOrganisation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.survey.SurveyReportOrganisation'] = SurveyReportOrganisation;

reverseMapping[SurveyReportOrganisation.name] = 'fr.hiji.metier.reporting.entities.survey.SurveyReportOrganisation';

fields['fr.hiji.metier.reporting.entities.survey.SurveyReportOrganisation']  = {
    idSurveyReport: 'java.lang.Integer',
    idOrganisation: 'java.lang.Integer'
};
