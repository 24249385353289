// ENTITY fr.hiji.metier.campaign.dto.front.RepositoryAccessDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class RepositoryAccessDTO {

  idRepository: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  order: number | null = 0;
  nbObservation: number | null = 0;
  nbAutoObservation: number | null = 0;

  constructor(init?:Partial<RepositoryAccessDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.RepositoryAccessDTO'] = RepositoryAccessDTO;

reverseMapping[RepositoryAccessDTO.name] = 'fr.hiji.metier.campaign.dto.front.RepositoryAccessDTO';

fields['fr.hiji.metier.campaign.dto.front.RepositoryAccessDTO']  = {
    idRepository: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    order: 'java.lang.Integer',
    nbObservation: 'java.lang.Long',
    nbAutoObservation: 'java.lang.Long'
};
