// ENTITY fr.hiji.metier.mif.entities.event.FormationEvent generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationEvent {

  idFormationEvent: number | null = 0;
  idRepositoryGlobalSatisfaction: number | null = null;
  idRepositoryDefaultAnimationSatisfaction: number | null = 0;
  idRepositoryAcquisition: number | null = null;
  idOrganisationStorage: number | null = null;
  name: MultilingualString | null = new MultilingualString();
  startDate: Date | null = null;
  endDate: Date | null = null;
  nbParticipantSlots: number | null = 0;
  nbQueueSlots: number | null = 0;
  acquisitionBeforeAvailable: boolean | null = false;
  acquisitionAfterAvailable: boolean | null = false;
  satisfactionAvailable: boolean | null = false;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<FormationEvent>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.FormationEvent'] = FormationEvent;

reverseMapping[FormationEvent.name] = 'fr.hiji.metier.mif.entities.event.FormationEvent';

fields['fr.hiji.metier.mif.entities.event.FormationEvent']  = {
    idFormationEvent: 'java.lang.Integer',
    idRepositoryGlobalSatisfaction: 'java.lang.Integer',
    idRepositoryDefaultAnimationSatisfaction: 'java.lang.Integer',
    idRepositoryAcquisition: 'java.lang.Integer',
    idOrganisationStorage: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    nbParticipantSlots: 'java.lang.Integer',
    nbQueueSlots: 'java.lang.Integer',
    acquisitionBeforeAvailable: 'java.lang.Boolean',
    acquisitionAfterAvailable: 'java.lang.Boolean',
    satisfactionAvailable: 'java.lang.Boolean',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
