// ENTITY fr.hiji.metier.mif.dto.back.stats.FormationExpectationStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationExpectationStatsBODTO {

  idFormationSessionParticipantExpectation: number | null = 0;
  expectation: string | null = '';
  observed: boolean | null = false;
  note: number | null = 0;

  constructor(init?:Partial<FormationExpectationStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.FormationExpectationStatsBODTO'] = FormationExpectationStatsBODTO;

reverseMapping[FormationExpectationStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.FormationExpectationStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.FormationExpectationStatsBODTO']  = {
    idFormationSessionParticipantExpectation: 'java.lang.Integer',
    expectation: 'java.lang.String',
    observed: 'java.lang.Boolean',
    note: 'java.lang.Integer'
};
