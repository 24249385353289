// ENTITY fr.hiji.metier.user.dto.front.UserFuncAvatarDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserFuncAvatarDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  functionName: string | null = '';
  avatarExtension: string | null = '';
  email: string | null = '';
  external: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<UserFuncAvatarDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.UserFuncAvatarDTO'] = UserFuncAvatarDTO;

reverseMapping[UserFuncAvatarDTO.name] = 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO';

fields['fr.hiji.metier.user.dto.front.UserFuncAvatarDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    functionName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    email: 'java.lang.String',
    external: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
