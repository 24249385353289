// ENTITY fr.hiji.metier.fbs.dto.FeedbackUserDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FeedbackUserDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  fonction: string | null = '';
  avatarExtension: string | null = '';
  viewedDate: Date | null = null;

  constructor(init?:Partial<FeedbackUserDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.dto.FeedbackUserDTO'] = FeedbackUserDTO;

reverseMapping[FeedbackUserDTO.name] = 'fr.hiji.metier.fbs.dto.FeedbackUserDTO';

fields['fr.hiji.metier.fbs.dto.FeedbackUserDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonction: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    viewedDate: 'java.time.Instant'
};
