// fr.hiji.metier.reseau.services.back.PopulationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from '../entities/UserFuncAvatarDTO';
import { PopulationItemBODTO } from '../entities/PopulationItemBODTO';
import { PopulationBODTO } from '../entities/PopulationBODTO';

@Injectable({
  providedIn: 'root',
})
export class PopulationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPopulationList(filter: GenericFilter): RpcRequestBuilder<ListWithCount<PopulationItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PopulationBOService.getPopulationList';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getPopulation(idPopulation: number): RpcRequestBuilder<PopulationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PopulationBOService.getPopulation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPopulation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reseau.dto.PopulationBODTO');
  }

  populationNameAlreadyExists(name: string, idPopulation: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PopulationBOService.populationNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPopulation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getUsersInPopulation(idPopulation: number): RpcRequestBuilder<UserFuncAvatarDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PopulationBOService.getUsersInPopulation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPopulation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  savePopulation(gf: PopulationBODTO): RpcRequestBuilder<PopulationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PopulationBOService.savePopulation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(gf, 'fr.hiji.metier.reseau.dto.PopulationBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reseau.dto.PopulationBODTO');
  }

  getMembersCSV(idPopulation: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PopulationBOService.getMembersCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPopulation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  createPopulationFromCSV(name: MultilingualString, idOrganisation: number): RpcRequestBuilder<PopulationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PopulationBOService.createPopulationFromCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idOrganisation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reseau.dto.PopulationBODTO');
  }

}
