// ENTITY fr.hiji.metier.ema.entities.objectif.EMAObjectiveDefinition generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAObjectiveDefinition {

  idEMAObjectiveDefinition: number | null = 0;
  idEMAObjective: number | null = 0;
  idUser: number | null = 0;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  deadlineDate: Date | null = null;
  objective: number | null = null;
  idEMA: number | null = null;

  constructor(init?:Partial<EMAObjectiveDefinition>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.objectif.EMAObjectiveDefinition'] = EMAObjectiveDefinition;

reverseMapping[EMAObjectiveDefinition.name] = 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveDefinition';

fields['fr.hiji.metier.ema.entities.objectif.EMAObjectiveDefinition']  = {
    idEMAObjectiveDefinition: 'java.lang.Integer',
    idEMAObjective: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    deadlineDate: 'java.time.LocalDate',
    objective: 'java.lang.Double',
    idEMA: 'java.lang.Integer'
};
