// DB ENUM fr.hiji.metier.ema.entities.EMAPerceptionType generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMAPerceptionType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly FIERTE = new EMAPerceptionType(1, "Fierté", "FIERTE");
  static readonly DIFFICULTE = new EMAPerceptionType(2, "Difficulté", "DIFFICULTE");
  static readonly BESOIN = new EMAPerceptionType(3, "Besoin", "BESOIN");

  static readonly values = [
    EMAPerceptionType.FIERTE,
 
    EMAPerceptionType.DIFFICULTE,
 
    EMAPerceptionType.BESOIN
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMAPerceptionType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMAPerceptionType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.EMAPerceptionType'] = EMAPerceptionType;
reverseMapping[EMAPerceptionType.name] = 'fr.hiji.metier.ema.entities.EMAPerceptionType';
