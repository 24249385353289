// ENTITY fr.hiji.metier.referential.dto.RepositoryLiteDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class RepositoryLiteDTO {

  idRepository: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  order: number | null = 0;

  constructor(init?:Partial<RepositoryLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.RepositoryLiteDTO'] = RepositoryLiteDTO;

reverseMapping[RepositoryLiteDTO.name] = 'fr.hiji.metier.referential.dto.RepositoryLiteDTO';

fields['fr.hiji.metier.referential.dto.RepositoryLiteDTO']  = {
    idRepository: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    order: 'java.lang.Integer'
};
