// fr.hiji.metier.campaign.services.back.CampaignEmailBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { Email } from '../entities/Email';
import { CampaignType } from '../entities/CampaignType';

@Injectable({
  providedIn: 'root',
})
export class CampaignEmailBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEmails(type: CampaignType): RpcRequestBuilder<Email[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignEmailBOService.getEmails';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(type, 'fr.hiji.metier.campaign.entities.CampaignType', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
