// DB ENUM fr.hiji.metier.ema.entities.EMAOGRemoveReason generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMAOGRemoveReason {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly MASTERED = new EMAOGRemoveReason(1, "Maitrisé", "MASTERED");
  static readonly MIGHT_BE_USEFULL_LATER = new EMAOGRemoveReason(2, "Le geste sera utile plus tard", "MIGHT_BE_USEFULL_LATER");
  static readonly NON_APPLICABLE = new EMAOGRemoveReason(3, "Le geste est non-applicable", "NON_APPLICABLE");

  static readonly values = [
    EMAOGRemoveReason.MASTERED,
 
    EMAOGRemoveReason.MIGHT_BE_USEFULL_LATER,
 
    EMAOGRemoveReason.NON_APPLICABLE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMAOGRemoveReason.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMAOGRemoveReason.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.EMAOGRemoveReason'] = EMAOGRemoveReason;
reverseMapping[EMAOGRemoveReason.name] = 'fr.hiji.metier.ema.entities.EMAOGRemoveReason';
