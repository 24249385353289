// fr.hiji.metier.home.services.front.HomeServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { HomeDTO } from '../entities/HomeDTO';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTasks(): RpcRequestBuilder<HomeDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'HomeService.getTasks';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.home.dto.front.HomeDTO');
  }

}
