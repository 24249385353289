// ENTITY fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIInfosBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignPDIInfos } from './CampaignPDIInfos';
import { CampaignPDIFormationBODTO } from './CampaignPDIFormationBODTO';
import { CampaignEmail } from './CampaignEmail';
import { CampaignPDIUserResponsableBODTO } from './CampaignPDIUserResponsableBODTO';
import { CampaignPDIUserBODTO } from './CampaignPDIUserBODTO';

export class CampaignPDIInfosBODTO {

  campaignPDIInfos: CampaignPDIInfos | null = null;
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDel: boolean | null = false;
  populationName: MultilingualString | null = new MultilingualString();
  populationDel: boolean | null = false;
  responsables: CampaignPDIUserResponsableBODTO[] | null = [];
  users: CampaignPDIUserBODTO[] | null = [];
  emails: CampaignEmail[] | null = [];
  formations: CampaignPDIFormationBODTO[] | null = [];

  constructor(init?:Partial<CampaignPDIInfosBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIInfosBODTO'] = CampaignPDIInfosBODTO;

reverseMapping[CampaignPDIInfosBODTO.name] = 'fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIInfosBODTO';

fields['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIInfosBODTO']  = {
    campaignPDIInfos: 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIInfos',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDel: 'java.lang.Boolean',
    populationName: 'com.ic2.sc.MultilingualString',
    populationDel: 'java.lang.Boolean',
    responsables: 'java.util.List<fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserResponsableBODTO>',
    users: 'java.util.List<fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserBODTO>',
    emails: 'java.util.List<fr.hiji.metier.campaign.entities.CampaignEmail>',
    formations: 'java.util.List<fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIFormationBODTO>'
};
