// ENTITY fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidateDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class CertificationSessionCandidateDTO {

  user: UserFuncAvatarDTO | null = null;
  certified: boolean | null = false;

  constructor(init?:Partial<CertificationSessionCandidateDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidateDTO'] = CertificationSessionCandidateDTO;

reverseMapping[CertificationSessionCandidateDTO.name] = 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidateDTO';

fields['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidateDTO']  = {
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    certified: 'java.lang.Boolean'
};
