// ENTITY fr.hiji.metier.referential.dto.back.QuizItemBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Quiz } from './Quiz';

export class QuizItemBODTO {

  quiz: Quiz | null = null;
  nbQuestion: number | null = 0;

  constructor(init?:Partial<QuizItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.back.QuizItemBODTO'] = QuizItemBODTO;

reverseMapping[QuizItemBODTO.name] = 'fr.hiji.metier.referential.dto.back.QuizItemBODTO';

fields['fr.hiji.metier.referential.dto.back.QuizItemBODTO']  = {
    quiz: 'fr.hiji.metier.referential.entities.quiz.Quiz',
    nbQuestion: 'java.lang.Integer'
};
