// fr.hiji.metier.mif.services.back.event.stats.FormationEventStatsFilterBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from '../entities/UserFuncAvatarDTO';
import { FormationSessionStatsLiteDTO } from '../entities/FormationSessionStatsLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationEventStatsFilterBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEvents(idUser: number): RpcRequestBuilder<FormationSessionStatsLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsFilterBOService.getEvents';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getTrainers(): RpcRequestBuilder<UserFuncAvatarDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventStatsFilterBOService.getTrainers';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
