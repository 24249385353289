// ENTITY fr.hiji.metier.campaign.dto.front._360.InquiryTargetDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { InquiryTarget } from './InquiryTarget';

export class InquiryTargetDTO {

  target: InquiryTarget | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  functionName: string | null = '';
  avatarExtension: string | null = '';
  del: boolean | null = false;
  anonymous: boolean | null = false;
  answered: boolean | null = false;
  answerDate: Date | null = null;
  draft: boolean | null = false;

  constructor(init?:Partial<InquiryTargetDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front._360.InquiryTargetDTO'] = InquiryTargetDTO;

reverseMapping[InquiryTargetDTO.name] = 'fr.hiji.metier.campaign.dto.front._360.InquiryTargetDTO';

fields['fr.hiji.metier.campaign.dto.front._360.InquiryTargetDTO']  = {
    target: 'fr.hiji.metier.campaign.entities._360.InquiryTarget',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    functionName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    del: 'java.lang.Boolean',
    anonymous: 'java.lang.Boolean',
    answered: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    draft: 'java.lang.Boolean'
};
