// fr.hiji.metier.certif.services.front.InterviewModalityServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ModalityInterviewDTO } from '../entities/ModalityInterviewDTO';
import { ModalityInterviewResultDTO } from '../entities/ModalityInterviewResultDTO';

@Injectable({
  providedIn: 'root',
})
export class InterviewModalityService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getResult(idExam: number): RpcRequestBuilder<ModalityInterviewResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InterviewModalityService.getResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.interview.result.ModalityInterviewResultDTO');
  }

  updateEvaluation(idExam: number, value: number, succeeded: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InterviewModalityService.updateEvaluation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(value, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(succeeded, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  answerInterview(interview: ModalityInterviewDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InterviewModalityService.answerInterview';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(interview, 'fr.hiji.metier.certif.dto.front.modality.interview.ModalityInterviewDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getInterviewModality(idExam: number): RpcRequestBuilder<ModalityInterviewDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InterviewModalityService.getInterviewModality';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.interview.ModalityInterviewDTO');
  }

}
