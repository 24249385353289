// ENTITY fr.hiji.metier.certif.dto.back.stats.ModalityListStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ModalityListStatsBODTO {

  idModality: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  nbParticipant: number | null = 0;
  nbExamen: number | null = 0;
  nbSuccess: number | null = 0;
  nbEchec: number | null = 0;
  nbSessions: number | null = 0;

  constructor(init?:Partial<ModalityListStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.ModalityListStatsBODTO'] = ModalityListStatsBODTO;

reverseMapping[ModalityListStatsBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.ModalityListStatsBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.ModalityListStatsBODTO']  = {
    idModality: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    nbParticipant: 'java.lang.Integer',
    nbExamen: 'java.lang.Integer',
    nbSuccess: 'java.lang.Integer',
    nbEchec: 'java.lang.Integer',
    nbSessions: 'java.lang.Integer'
};
