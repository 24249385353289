// ENTITY fr.hiji.metier.ema.dto.front.EMARepositoryLiteDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMARepositoryLiteDTO {

  idRepository: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  order: number | null = 0;
  idsTags: number[] | null = [];
  nbGestures: number | null = 0;

  constructor(init?:Partial<EMARepositoryLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMARepositoryLiteDTO'] = EMARepositoryLiteDTO;

reverseMapping[EMARepositoryLiteDTO.name] = 'fr.hiji.metier.ema.dto.front.EMARepositoryLiteDTO';

fields['fr.hiji.metier.ema.dto.front.EMARepositoryLiteDTO']  = {
    idRepository: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    order: 'java.lang.Integer',
    idsTags: 'java.util.List<java.lang.Integer>',
    nbGestures: 'java.lang.Integer'
};
