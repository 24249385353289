// fr.hiji.metier.ema.services.front.EMAObservationServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { OpinionDTO } from '../entities/OpinionDTO';
import { EMAObservationDTO } from '../entities/EMAObservationDTO';

@Injectable({
  providedIn: 'root',
})
export class EMAObservationService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getObservation(idUser: number): RpcRequestBuilder<EMAObservationDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAObservationService.getObservation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.front.EMAObservationDTO');
  }

  saveObservation(emaObservationDTO: EMAObservationDTO): RpcRequestBuilder<OpinionDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAObservationService.saveObservation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(emaObservationDTO, 'fr.hiji.metier.ema.dto.front.EMAObservationDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.opinion.dto.front.OpinionDTO');
  }

}
