// ENTITY fr.hiji.metier.campaign.entities.CampaignEmail generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { HijiEmail } from './HijiEmail';

export class CampaignEmail {

  idCampaign: number | null = 0;
  emailType: HijiEmail | null = null;
  senderEmail: string | null = '';
  senderName: MultilingualString | null = new MultilingualString();
  subject: MultilingualString | null = new MultilingualString();
  content: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<CampaignEmail>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.CampaignEmail'] = CampaignEmail;

reverseMapping[CampaignEmail.name] = 'fr.hiji.metier.campaign.entities.CampaignEmail';

fields['fr.hiji.metier.campaign.entities.CampaignEmail']  = {
    idCampaign: 'java.lang.Integer',
    emailType: 'fr.hiji.metier.tools.email.HijiEmail',
    senderEmail: 'java.lang.String',
    senderName: 'com.ic2.sc.MultilingualString',
    subject: 'com.ic2.sc.MultilingualString',
    content: 'com.ic2.sc.MultilingualString'
};
