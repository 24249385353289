// fr.hiji.metier.conf.services.back.CSSConfigBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class CSSConfigBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCSS(): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CSSConfigBOService.getCSS';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  saveCSS(data: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CSSConfigBOService.saveCSS';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(data, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
