// DB ENUM fr.hiji.metier.campaign.entities.CampaignType generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class CampaignType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly NORMAL = new CampaignType(1, "Normal", "NORMAL");
  static readonly ENQUETE = new CampaignType(2, "Enquête", "ENQUETE");
  static readonly _360 = new CampaignType(3, "360", "_360");
  static readonly PDI = new CampaignType(4, "PDI", "PDI");

  static readonly values = [
    CampaignType.NORMAL,
 
    CampaignType.ENQUETE,
 
    CampaignType._360,
 
    CampaignType.PDI
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of CampaignType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of CampaignType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.campaign.entities.CampaignType'] = CampaignType;
reverseMapping[CampaignType.name] = 'fr.hiji.metier.campaign.entities.CampaignType';
