// ENTITY fr.hiji.metier.campaign.dto.back.stats.OGStatPerObservationTypeBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ObservationType } from './ObservationType';

export class OGStatPerObservationTypeBODTO {

  type: ObservationType | null = null;
  nb: number | null = 0;
  avg: number | null = null;
  nbFirstHalf: number | null = 0;
  avgFirstHalf: number | null = null;
  nbSecondHalf: number | null = 0;
  avgSecondHalf: number | null = null;

  constructor(init?:Partial<OGStatPerObservationTypeBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.OGStatPerObservationTypeBODTO'] = OGStatPerObservationTypeBODTO;

reverseMapping[OGStatPerObservationTypeBODTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.OGStatPerObservationTypeBODTO';

fields['fr.hiji.metier.campaign.dto.back.stats.OGStatPerObservationTypeBODTO']  = {
    type: 'fr.hiji.metier.campaign.entities.ObservationType',
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbFirstHalf: 'java.lang.Integer',
    avgFirstHalf: 'java.lang.Double',
    nbSecondHalf: 'java.lang.Integer',
    avgSecondHalf: 'java.lang.Double'
};
