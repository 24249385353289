// ENTITY fr.hiji.metier.mif.entities.trainingpath.TrainingPath generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class TrainingPath {

  idTrainingPath: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  idPopulation: number | null = null;
  idOrganisationStorage: number | null = 0;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<TrainingPath>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.trainingpath.TrainingPath'] = TrainingPath;

reverseMapping[TrainingPath.name] = 'fr.hiji.metier.mif.entities.trainingpath.TrainingPath';

fields['fr.hiji.metier.mif.entities.trainingpath.TrainingPath']  = {
    idTrainingPath: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    idPopulation: 'java.lang.Integer',
    idOrganisationStorage: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
