// ENTITY fr.hiji.metier.reseau.entities.Population generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Population {

  idPopulation: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  idOrganisationStorage: number | null = null;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<Population>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.entities.Population'] = Population;

reverseMapping[Population.name] = 'fr.hiji.metier.reseau.entities.Population';

fields['fr.hiji.metier.reseau.entities.Population']  = {
    idPopulation: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    idOrganisationStorage: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
