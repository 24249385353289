// ENTITY fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExam generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityQuizExam {

  idExam: number | null = 0;
  idUserObserver: number | null = 0;
  startAnsweringDate: Date | null = null;
  draft: boolean | null = false;
  score: number | null = 0;
  total: number | null = 0;

  constructor(init?:Partial<ModalityQuizExam>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExam'] = ModalityQuizExam;

reverseMapping[ModalityQuizExam.name] = 'fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExam';

fields['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExam']  = {
    idExam: 'java.lang.Integer',
    idUserObserver: 'java.lang.Integer',
    startAnsweringDate: 'java.time.Instant',
    draft: 'java.lang.Boolean',
    score: 'java.lang.Double',
    total: 'java.lang.Double'
};
