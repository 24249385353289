// ENTITY fr.hiji.metier.campaign.dto.back._360.Campaign360BODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { Campaign360SkillTarget } from './Campaign360SkillTarget';
import { Campaign360ObservableGestureBODTO } from './Campaign360ObservableGestureBODTO';
import { Campaign360Relance } from './Campaign360Relance';
import { Campaign360Infos } from './Campaign360Infos';
import { UserInquiryBODTO } from './UserInquiryBODTO';

export class Campaign360BODTO {

  campaign360Infos: Campaign360Infos | null = null;
  regionName: string | null = '';
  populationName: MultilingualString | null = new MultilingualString();
  responsibleUserName: string | null = '';
  populationDel: boolean | null = false;
  observableGestures: Campaign360ObservableGestureBODTO[] | null = [];
  userInquiries: UserInquiryBODTO[] | null = [];
  relances: Campaign360Relance[] | null = [];
  skillTargets: Campaign360SkillTarget[] | null = [];

  constructor(init?:Partial<Campaign360BODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back._360.Campaign360BODTO'] = Campaign360BODTO;

reverseMapping[Campaign360BODTO.name] = 'fr.hiji.metier.campaign.dto.back._360.Campaign360BODTO';

fields['fr.hiji.metier.campaign.dto.back._360.Campaign360BODTO']  = {
    campaign360Infos: 'fr.hiji.metier.campaign.entities._360.Campaign360Infos',
    regionName: 'java.lang.String',
    populationName: 'com.ic2.sc.MultilingualString',
    responsibleUserName: 'java.lang.String',
    populationDel: 'java.lang.Boolean',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.back._360.Campaign360ObservableGestureBODTO>',
    userInquiries: 'java.util.List<fr.hiji.metier.campaign.dto.back._360.UserInquiryBODTO>',
    relances: 'java.util.List<fr.hiji.metier.campaign.entities._360.Campaign360Relance>',
    skillTargets: 'java.util.List<fr.hiji.metier.campaign.entities._360.Campaign360SkillTarget>'
};
