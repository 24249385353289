// ENTITY fr.hiji.metier.page.entities.StaticPage generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class StaticPage {

  idStaticPage: number | null = 0;
  slug: string | null = '';
  html: MultilingualString | null = new MultilingualString();
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  displayCount: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<StaticPage>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.page.entities.StaticPage'] = StaticPage;

reverseMapping[StaticPage.name] = 'fr.hiji.metier.page.entities.StaticPage';

fields['fr.hiji.metier.page.entities.StaticPage']  = {
    idStaticPage: 'java.lang.Integer',
    slug: 'java.lang.String',
    html: 'com.ic2.sc.MultilingualString',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    displayCount: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
