// ENTITY fr.hiji.metier.translate.entities.DataTranslatorBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { AttributBODTO } from './AttributBODTO';

export class DataTranslatorBODTO {

  ids: number[] | null = [];
  data: AttributBODTO[] | null = [];

  constructor(init?:Partial<DataTranslatorBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.translate.entities.DataTranslatorBODTO'] = DataTranslatorBODTO;

reverseMapping[DataTranslatorBODTO.name] = 'fr.hiji.metier.translate.entities.DataTranslatorBODTO';

fields['fr.hiji.metier.translate.entities.DataTranslatorBODTO']  = {
    ids: 'java.util.List<java.lang.Integer>',
    data: 'java.util.List<fr.hiji.metier.translate.entities.AttributBODTO>'
};
