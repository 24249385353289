// fr.hiji.metier.reseau.services.common.FonctionServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FonctionLiteDTO } from '../entities/FonctionLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class FonctionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFonctions(): RpcRequestBuilder<FonctionLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionService.getFonctions';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
