// fr.hiji.metier.mif.services.back.trainingpath.TrainingPathBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { TrainingPathItemBODTO } from '../entities/TrainingPathItemBODTO';
import { TrainingPathBODTO } from '../entities/TrainingPathBODTO';

@Injectable({
  providedIn: 'root',
})
export class TrainingPathBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTrainingPaths(filter: GenericFilter): RpcRequestBuilder<ListWithCount<TrainingPathItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TrainingPathBOService.getTrainingPaths';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getTrainingPath(id: number): RpcRequestBuilder<TrainingPathBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TrainingPathBOService.getTrainingPath';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathBODTO');
  }

  saveTrainingPath(dto: TrainingPathBODTO): RpcRequestBuilder<TrainingPathBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TrainingPathBOService.saveTrainingPath';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathBODTO');
  }

}
