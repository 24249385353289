// ENTITY fr.hiji.metier.campaign.dto.back.survey.SurveyBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignSurveyInfos } from './CampaignSurveyInfos';
import { SurveyUserBODTO } from './SurveyUserBODTO';
import { SurveyRelance } from './SurveyRelance';

export class SurveyBODTO {

  campaignSurveyInfos: CampaignSurveyInfos | null = null;
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDel: boolean | null = false;
  populationName: MultilingualString | null = new MultilingualString();
  populationDel: boolean | null = false;
  populationForReportingName: MultilingualString | null = new MultilingualString();
  populationForReportingDel: boolean | null = false;
  nbUserReportingWithoutPerimeter: number | null = 0;
  users: SurveyUserBODTO[] | null = [];
  relances: SurveyRelance[] | null = [];
  previousCampaignName: MultilingualString | null = new MultilingualString();
  existingTemplateLanguages: string[] | null = [];

  constructor(init?:Partial<SurveyBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.SurveyBODTO'] = SurveyBODTO;

reverseMapping[SurveyBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.SurveyBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.SurveyBODTO']  = {
    campaignSurveyInfos: 'fr.hiji.metier.campaign.entities.survey.CampaignSurveyInfos',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDel: 'java.lang.Boolean',
    populationName: 'com.ic2.sc.MultilingualString',
    populationDel: 'java.lang.Boolean',
    populationForReportingName: 'com.ic2.sc.MultilingualString',
    populationForReportingDel: 'java.lang.Boolean',
    nbUserReportingWithoutPerimeter: 'java.lang.Integer',
    users: 'java.util.List<fr.hiji.metier.campaign.dto.back.survey.SurveyUserBODTO>',
    relances: 'java.util.List<fr.hiji.metier.campaign.entities.survey.SurveyRelance>',
    previousCampaignName: 'com.ic2.sc.MultilingualString',
    existingTemplateLanguages: 'java.util.List<java.lang.String>'
};
