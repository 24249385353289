// ENTITY fr.hiji.metier.ema.entities.EMASkillMessage generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMASkillMessage {

  idEMA: number | null = 0;
  idTag: number | null = 0;
  message: string | null = '';

  constructor(init?:Partial<EMASkillMessage>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMASkillMessage'] = EMASkillMessage;

reverseMapping[EMASkillMessage.name] = 'fr.hiji.metier.ema.entities.EMASkillMessage';

fields['fr.hiji.metier.ema.entities.EMASkillMessage']  = {
    idEMA: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    message: 'java.lang.String'
};
