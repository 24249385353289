// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleTMSDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleTMSDTO {

  moduleTMSEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleTMSDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleTMSDTO'] = ModuleTMSDTO;

reverseMapping[ModuleTMSDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleTMSDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleTMSDTO']  = {
    moduleTMSEnabled: 'java.lang.Boolean'
};
