// ENTITY fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityQuiz {

  idModality: number | null = 0;
  idQuiz: number | null = 0;
  showScore: boolean | null = false;
  showCorrection: boolean | null = false;
  randomizeQuestionOrder: boolean | null = false;
  randomizeAnswerOrder: boolean | null = false;
  hideSkills: boolean | null = false;
  canUseDraft: boolean | null = false;
  showTargetForCandidate: boolean | null = false;
  pointsToSucceed: number | null = 0;
  questionPoolEnabled: boolean | null = false;

  constructor(init?:Partial<ModalityQuiz>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz'] = ModalityQuiz;

reverseMapping[ModalityQuiz.name] = 'fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz';

fields['fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz']  = {
    idModality: 'java.lang.Integer',
    idQuiz: 'java.lang.Integer',
    showScore: 'java.lang.Boolean',
    showCorrection: 'java.lang.Boolean',
    randomizeQuestionOrder: 'java.lang.Boolean',
    randomizeAnswerOrder: 'java.lang.Boolean',
    hideSkills: 'java.lang.Boolean',
    canUseDraft: 'java.lang.Boolean',
    showTargetForCandidate: 'java.lang.Boolean',
    pointsToSucceed: 'java.lang.Double',
    questionPoolEnabled: 'java.lang.Boolean'
};
