// ENTITY fr.hiji.metier.ema.dto.front.EMAOGRepositoryLink generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAOGRepositoryLink {

  idRepository: number | null = 0;
  order: number | null = 0;

  constructor(init?:Partial<EMAOGRepositoryLink>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAOGRepositoryLink'] = EMAOGRepositoryLink;

reverseMapping[EMAOGRepositoryLink.name] = 'fr.hiji.metier.ema.dto.front.EMAOGRepositoryLink';

fields['fr.hiji.metier.ema.dto.front.EMAOGRepositoryLink']  = {
    idRepository: 'java.lang.Integer',
    order: 'java.lang.Integer'
};
