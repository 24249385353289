// ENTITY fr.hiji.metier.ema.dto.back.stats.EMAParticipationStatsDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ExchangePerWeekDTO } from './ExchangePerWeekDTO';
import { EMAManagerStatDTO } from './EMAManagerStatDTO';
import { EMAEtablissementStatsBODTO } from './EMAEtablissementStatsBODTO';

export class EMAParticipationStatsDTO {

  nbObservations: number | null = 0;
  nbAutoObservations: number | null = 0;
  nbEMA: number | null = 0;
  nbActiveUsers: number | null = 0;
  nbTotalUsers: number | null = 0;
  exchangePerWeeks: ExchangePerWeekDTO[] | null = [];
  managers: EMAManagerStatDTO[] | null = [];
  etablissements: EMAEtablissementStatsBODTO[] | null = [];
  nbTargetUser: number | null = 0;
  nbMiniUserToShowStats: number | null = 0;

  constructor(init?:Partial<EMAParticipationStatsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMAParticipationStatsDTO'] = EMAParticipationStatsDTO;

reverseMapping[EMAParticipationStatsDTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMAParticipationStatsDTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMAParticipationStatsDTO']  = {
    nbObservations: 'java.lang.Integer',
    nbAutoObservations: 'java.lang.Integer',
    nbEMA: 'java.lang.Integer',
    nbActiveUsers: 'java.lang.Integer',
    nbTotalUsers: 'java.lang.Integer',
    exchangePerWeeks: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.ExchangePerWeekDTO>',
    managers: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMAManagerStatDTO>',
    etablissements: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMAEtablissementStatsBODTO>',
    nbTargetUser: 'java.lang.Integer',
    nbMiniUserToShowStats: 'java.lang.Integer'
};
