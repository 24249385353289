// ENTITY fr.hiji.metier.teamboard.entities.TeamBoard generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class TeamBoard {

  idTeamBoard: number | null = 0;
  idManager: number | null = 0;
  creationDate: Date | null = null;
  visible: boolean | null = false;
  news: string | null = '';
  results: string | null = '';
  goal: string | null = '';
  questionsAnswers: string | null = '';
  conclusion: string | null = '';

  constructor(init?:Partial<TeamBoard>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.entities.TeamBoard'] = TeamBoard;

reverseMapping[TeamBoard.name] = 'fr.hiji.metier.teamboard.entities.TeamBoard';

fields['fr.hiji.metier.teamboard.entities.TeamBoard']  = {
    idTeamBoard: 'java.lang.Integer',
    idManager: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    visible: 'java.lang.Boolean',
    news: 'java.lang.String',
    results: 'java.lang.String',
    goal: 'java.lang.String',
    questionsAnswers: 'java.lang.String',
    conclusion: 'java.lang.String'
};
