// ENTITY fr.hiji.metier.campaign.dto.back.stats.ObservableGestureChartValueDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ObservableGestureChartValueDTO {

  idObservableGesture: number | null = 0;
  month: string | null = '';
  avg: number | null = null;
  nb: number | null = 0;
  autoObsAvg: number | null = null;
  nbAutoObs: number | null = 0;
  obsAvg: number | null = null;
  nbObs: number | null = 0;

  constructor(init?:Partial<ObservableGestureChartValueDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.ObservableGestureChartValueDTO'] = ObservableGestureChartValueDTO;

reverseMapping[ObservableGestureChartValueDTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.ObservableGestureChartValueDTO';

fields['fr.hiji.metier.campaign.dto.back.stats.ObservableGestureChartValueDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    month: 'java.lang.String',
    avg: 'java.lang.Double',
    nb: 'java.lang.Integer',
    autoObsAvg: 'java.lang.Double',
    nbAutoObs: 'java.lang.Integer',
    obsAvg: 'java.lang.Double',
    nbObs: 'java.lang.Integer'
};
