// ENTITY fr.hiji.metier.user.dto.front.TeamMemberDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class TeamMemberDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';
  functionName: string | null = '';
  nbObservation: number | null = 0;
  nbAutoObservation: number | null = 0;
  lastObservationDate: Date | null = null;
  lastAutoObservationDate: Date | null = null;
  lastExchangeDate: Date | null = null;
  campaignExists: boolean | null = false;
  isPDIAvailable: boolean | null = false;
  isTargetedByEMA: boolean | null = false;
  isWithPlanNextStep: boolean | null = false;
  nextObsOrAutoObsHintDateEMA: Date | null = null;
  nextEMADate: Date | null = null;
  nbObsToDoEMA: number | null = 0;
  nbObsOrAutoObsEMA: number | null = 0;
  rank: number | null = 0;
  nbEMAInCurrentMonth: number | null = 0;

  constructor(init?:Partial<TeamMemberDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.TeamMemberDTO'] = TeamMemberDTO;

reverseMapping[TeamMemberDTO.name] = 'fr.hiji.metier.user.dto.front.TeamMemberDTO';

fields['fr.hiji.metier.user.dto.front.TeamMemberDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    functionName: 'java.lang.String',
    nbObservation: 'java.lang.Long',
    nbAutoObservation: 'java.lang.Long',
    lastObservationDate: 'java.time.Instant',
    lastAutoObservationDate: 'java.time.Instant',
    lastExchangeDate: 'java.time.Instant',
    campaignExists: 'java.lang.Boolean',
    isPDIAvailable: 'java.lang.Boolean',
    isTargetedByEMA: 'java.lang.Boolean',
    isWithPlanNextStep: 'java.lang.Boolean',
    nextObsOrAutoObsHintDateEMA: 'java.time.LocalDate',
    nextEMADate: 'java.time.Instant',
    nbObsToDoEMA: 'java.lang.Long',
    nbObsOrAutoObsEMA: 'java.lang.Long',
    rank: 'java.lang.Double',
    nbEMAInCurrentMonth: 'java.lang.Long'
};
