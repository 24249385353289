// ENTITY fr.hiji.metier.mif.dto.front.trainer.MenuTrainerDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionDate } from './FormationSessionDate';
import { FormationSessionEmailConfig } from './FormationSessionEmailConfig';
import { FormationSessionDatePeriod } from './FormationSessionDatePeriod';

export class MenuTrainerDTO {

  mailAcquisitionBeforeEnabled: boolean | null = false;
  mailSatisfactionEnabled: boolean | null = false;
  mailAcquisitionAfterEnabled: boolean | null = false;
  acquisitionBeforeAvailable: boolean | null = false;
  satisfactionAvailable: boolean | null = false;
  acquisitionAfterAvailable: boolean | null = false;
  actionAvailable: boolean | null = false;
  acquisitionEnabled: boolean | null = false;
  actionEnabled: boolean | null = false;
  attendanceEnabled: boolean | null = false;
  trainerCanSeeParticipantSatisfaction: boolean | null = false;
  expectationsParticipantEnabled: boolean | null = false;
  expectationsParticipantEvaluationEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;
  nbQuiz: number | null = 0;
  currentAttendanceCode: string | null = '';
  nbParticipantWithAttendanceValidated: number | null = 0;
  totalParticipant: number | null = 0;
  nbAcquisitionBeforeResult: number | null = 0;
  nbSatisfactionResult: number | null = 0;
  nbAcquisitionAfterResult: number | null = 0;
  dateSatisfaction: Date | null = null;
  dateBefore: Date | null = null;
  dateAfter: Date | null = null;
  emailConfigs: FormationSessionEmailConfig[] | null = [];
  maxDate: Date | null = null;
  openedAttendanceDate: Date | null = null;
  openedAttendancePeriod: FormationSessionDatePeriod | null = null;
  dates: FormationSessionDate[] | null = [];

  constructor(init?:Partial<MenuTrainerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.MenuTrainerDTO'] = MenuTrainerDTO;

reverseMapping[MenuTrainerDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.MenuTrainerDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.MenuTrainerDTO']  = {
    mailAcquisitionBeforeEnabled: 'java.lang.Boolean',
    mailSatisfactionEnabled: 'java.lang.Boolean',
    mailAcquisitionAfterEnabled: 'java.lang.Boolean',
    acquisitionBeforeAvailable: 'java.lang.Boolean',
    satisfactionAvailable: 'java.lang.Boolean',
    acquisitionAfterAvailable: 'java.lang.Boolean',
    actionAvailable: 'java.lang.Boolean',
    acquisitionEnabled: 'java.lang.Boolean',
    actionEnabled: 'java.lang.Boolean',
    attendanceEnabled: 'java.lang.Boolean',
    trainerCanSeeParticipantSatisfaction: 'java.lang.Boolean',
    expectationsParticipantEnabled: 'java.lang.Boolean',
    expectationsParticipantEvaluationEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean',
    nbQuiz: 'java.lang.Integer',
    currentAttendanceCode: 'java.lang.String',
    nbParticipantWithAttendanceValidated: 'java.lang.Integer',
    totalParticipant: 'java.lang.Integer',
    nbAcquisitionBeforeResult: 'java.lang.Integer',
    nbSatisfactionResult: 'java.lang.Integer',
    nbAcquisitionAfterResult: 'java.lang.Integer',
    dateSatisfaction: 'java.time.Instant',
    dateBefore: 'java.time.Instant',
    dateAfter: 'java.time.Instant',
    emailConfigs: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig>',
    maxDate: 'java.time.LocalDate',
    openedAttendanceDate: 'java.time.LocalDate',
    openedAttendancePeriod: 'fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod',
    dates: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionDate>'
};
