// ENTITY fr.hiji.metier.mif.entities.eva.EVA generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EVAStep } from './EVAStep';

export class EVA {

  idFormationSession: number | null = 0;
  idUserParticipant: number | null = 0;
  idUserManager: number | null = 0;
  dateEVA: Date | null = null;
  creationDate: Date | null = null;
  step: EVAStep | null = null;
  startExchangeDate: Date | null = null;
  endExchangeDate: Date | null = null;
  comment: string | null = '';

  constructor(init?:Partial<EVA>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.eva.EVA'] = EVA;

reverseMapping[EVA.name] = 'fr.hiji.metier.mif.entities.eva.EVA';

fields['fr.hiji.metier.mif.entities.eva.EVA']  = {
    idFormationSession: 'java.lang.Integer',
    idUserParticipant: 'java.lang.Integer',
    idUserManager: 'java.lang.Integer',
    dateEVA: 'java.time.Instant',
    creationDate: 'java.time.Instant',
    step: 'fr.hiji.metier.mif.entities.eva.EVAStep',
    startExchangeDate: 'java.time.Instant',
    endExchangeDate: 'java.time.Instant',
    comment: 'java.lang.String'
};
