// ENTITY fr.hiji.metier.mif.dto.front.FormationObservationObservableGestureDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CriteriaType } from './CriteriaType';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class FormationObservationObservableGestureDTO {

  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  idCriteria: number | null = 0;
  criteriaType: CriteriaType | null = null;
  idFormationObservation: number | null = 0;
  value: number | null = 0;
  observed: boolean | null = false;
  answerDate: Date | null = null;
  order: number | null = 0;
  text: string | null = '';
  tags: ObservableGestureFlatTag[] | null = [];

  constructor(init?:Partial<FormationObservationObservableGestureDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.FormationObservationObservableGestureDTO'] = FormationObservationObservableGestureDTO;

reverseMapping[FormationObservationObservableGestureDTO.name] = 'fr.hiji.metier.mif.dto.front.FormationObservationObservableGestureDTO';

fields['fr.hiji.metier.mif.dto.front.FormationObservationObservableGestureDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    idCriteria: 'java.lang.Integer',
    criteriaType: 'fr.hiji.metier.referential.entities.CriteriaType',
    idFormationObservation: 'java.lang.Integer',
    value: 'java.lang.Integer',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    order: 'java.lang.Integer',
    text: 'java.lang.String',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>'
};
