// ENTITY fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationRequirementBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { TrainingPathFormationRequirement } from './TrainingPathFormationRequirement';

export class TrainingPathFormationRequirementBODTO {

  trainingPathFormationRequirement: TrainingPathFormationRequirement | null = null;
  formationName: MultilingualString | null = new MultilingualString();
  formationImageExtension: string | null = '';
  formationDel: boolean | null = false;
  formationColorCode: string | null = '';

  constructor(init?:Partial<TrainingPathFormationRequirementBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationRequirementBODTO'] = TrainingPathFormationRequirementBODTO;

reverseMapping[TrainingPathFormationRequirementBODTO.name] = 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationRequirementBODTO';

fields['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathFormationRequirementBODTO']  = {
    trainingPathFormationRequirement: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormationRequirement',
    formationName: 'com.ic2.sc.MultilingualString',
    formationImageExtension: 'java.lang.String',
    formationDel: 'java.lang.Boolean',
    formationColorCode: 'java.lang.String'
};
