// ENTITY fr.hiji.metier.mif.dto.front.satisfaction.SatisfactionRepositoryAnswerDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationObservationObservableGestureDTO } from './FormationObservationObservableGestureDTO';
import { Criteria } from './Criteria';

export class SatisfactionRepositoryAnswerDTO {

  oogs: FormationObservationObservableGestureDTO[] | null = [];
  criterias: Criteria[] | null = [];
  formationName: MultilingualString | null = new MultilingualString();
  formationSessionName: string | null = '';
  minDate: Date | null = null;
  maxDate: Date | null = null;
  idFormationSession: number | null = 0;
  idFormationObservation: number | null = 0;

  constructor(init?:Partial<SatisfactionRepositoryAnswerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.satisfaction.SatisfactionRepositoryAnswerDTO'] = SatisfactionRepositoryAnswerDTO;

reverseMapping[SatisfactionRepositoryAnswerDTO.name] = 'fr.hiji.metier.mif.dto.front.satisfaction.SatisfactionRepositoryAnswerDTO';

fields['fr.hiji.metier.mif.dto.front.satisfaction.SatisfactionRepositoryAnswerDTO']  = {
    oogs: 'java.util.List<fr.hiji.metier.mif.dto.front.FormationObservationObservableGestureDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    formationName: 'com.ic2.sc.MultilingualString',
    formationSessionName: 'java.lang.String',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate',
    idFormationSession: 'java.lang.Integer',
    idFormationObservation: 'java.lang.Integer'
};
