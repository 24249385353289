// ENTITY fr.hiji.metier.mif.entities.formation.FormationEmail generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { HijiEmail } from './HijiEmail';

export class FormationEmail {

  idFormation: number | null = 0;
  emailType: HijiEmail | null = null;
  senderEmail: string | null = '';
  senderName: MultilingualString | null = new MultilingualString();
  subject: MultilingualString | null = new MultilingualString();
  content: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<FormationEmail>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.formation.FormationEmail'] = FormationEmail;

reverseMapping[FormationEmail.name] = 'fr.hiji.metier.mif.entities.formation.FormationEmail';

fields['fr.hiji.metier.mif.entities.formation.FormationEmail']  = {
    idFormation: 'java.lang.Integer',
    emailType: 'fr.hiji.metier.tools.email.HijiEmail',
    senderEmail: 'java.lang.String',
    senderName: 'com.ic2.sc.MultilingualString',
    subject: 'com.ic2.sc.MultilingualString',
    content: 'com.ic2.sc.MultilingualString'
};
