// fr.hiji.metier.reporting.services.back.ReportEMAConfigBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FrequenceRapport } from '../entities/FrequenceRapport';
import { ReportEmaDate } from '../entities/ReportEmaDate';
import { ReportEMAConfigBODTO } from '../entities/ReportEMAConfigBODTO';
import { ReportEMAConfigLiteBODTO } from '../entities/ReportEMAConfigLiteBODTO';

@Injectable({
  providedIn: 'root',
})
export class ReportEMAConfigBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  reportNameAlreadyExists(name: string, idReportEMAConfig: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportEMAConfigBOService.reportNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idReportEMAConfig, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getReportEMAConfigList(filter: GenericFilter): RpcRequestBuilder<ListWithCount<ReportEMAConfigLiteBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportEMAConfigBOService.getReportEMAConfigList';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getReportEMAConfig(idReportEMAConfig: number): RpcRequestBuilder<ReportEMAConfigBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportEMAConfigBOService.getReportEMAConfig';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idReportEMAConfig, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reporting.dto.back.ReportEMAConfigBODTO');
  }

  saveReportEMAConfig(dto: ReportEMAConfigBODTO): RpcRequestBuilder<ReportEMAConfigBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportEMAConfigBOService.saveReportEMAConfig';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.reporting.dto.back.ReportEMAConfigBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reporting.dto.back.ReportEMAConfigBODTO');
  }

  sendReportEmaMail(idReportEMAConfig: number, idUser: number, idOrganisation: number, date: Date, typeRapport: FrequenceRapport): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportEMAConfigBOService.sendReportEmaMail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idReportEMAConfig, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idOrganisation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(date, 'java.time.LocalDate', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(typeRapport, 'fr.hiji.metier.reporting.dto.pdf180.FrequenceRapport', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getReportEmaDate(): RpcRequestBuilder<ReportEmaDate[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportEMAConfigBOService.getReportEmaDate';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
