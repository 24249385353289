// ENTITY fr.hiji.metier.ema.dto.back.stats.EMAEtablissementStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAEtablissementStatsBODTO {

  idEtablissement: number | null = 0;
  etablissementName: string | null = '';
  regionName: string | null = '';
  effectifConcerne: number | null = 0;
  nbObs: number | null = 0;
  nbAutoObs: number | null = 0;
  nbExchanges: number | null = 0;
  nbActiveUsers: number | null = 0;

  constructor(init?:Partial<EMAEtablissementStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMAEtablissementStatsBODTO'] = EMAEtablissementStatsBODTO;

reverseMapping[EMAEtablissementStatsBODTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMAEtablissementStatsBODTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMAEtablissementStatsBODTO']  = {
    idEtablissement: 'java.lang.Integer',
    etablissementName: 'java.lang.String',
    regionName: 'java.lang.String',
    effectifConcerne: 'java.lang.Integer',
    nbObs: 'java.lang.Integer',
    nbAutoObs: 'java.lang.Integer',
    nbExchanges: 'java.lang.Integer',
    nbActiveUsers: 'java.lang.Integer'
};
