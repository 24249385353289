// ENTITY fr.hiji.metier.referential.dto.TagBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Tag } from './Tag';

export class TagBODTO {

  tag: Tag | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';

  constructor(init?:Partial<TagBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.TagBODTO'] = TagBODTO;

reverseMapping[TagBODTO.name] = 'fr.hiji.metier.referential.dto.TagBODTO';

fields['fr.hiji.metier.referential.dto.TagBODTO']  = {
    tag: 'fr.hiji.metier.referential.entities.Tag',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String'
};
