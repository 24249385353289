// ENTITY fr.hiji.metier.ema.dto.front.EMAObjectiveLiteDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMAObjectiveFormula } from './EMAObjectiveFormula';
import { EMAObjectiveType } from './EMAObjectiveType';

export class EMAObjectiveLiteDTO {

  idEMAObjective: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  unit: MultilingualString | null = new MultilingualString();
  type: EMAObjectiveType | null = null;
  shownInProfile: boolean | null = false;
  showAsPercentageInGraph: boolean | null = false;
  formula: EMAObjectiveFormula | null = null;

  constructor(init?:Partial<EMAObjectiveLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAObjectiveLiteDTO'] = EMAObjectiveLiteDTO;

reverseMapping[EMAObjectiveLiteDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAObjectiveLiteDTO';

fields['fr.hiji.metier.ema.dto.front.EMAObjectiveLiteDTO']  = {
    idEMAObjective: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    unit: 'com.ic2.sc.MultilingualString',
    type: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveType',
    shownInProfile: 'java.lang.Boolean',
    showAsPercentageInGraph: 'java.lang.Boolean',
    formula: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveFormula'
};
