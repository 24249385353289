// ENTITY fr.hiji.metier.mif.dto.front.FormationSessionDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationSessionDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  formationImageExtension: string | null = '';
  formationColorCode: string | null = '';
  idFormationSession: number | null = 0;
  formationSessionName: string | null = '';
  formationSessionColorCode: string | null = '';
  minDate: Date | null = null;
  maxDate: Date | null = null;

  constructor(init?:Partial<FormationSessionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.FormationSessionDTO'] = FormationSessionDTO;

reverseMapping[FormationSessionDTO.name] = 'fr.hiji.metier.mif.dto.front.FormationSessionDTO';

fields['fr.hiji.metier.mif.dto.front.FormationSessionDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    formationImageExtension: 'java.lang.String',
    formationColorCode: 'java.lang.String',
    idFormationSession: 'java.lang.Integer',
    formationSessionName: 'java.lang.String',
    formationSessionColorCode: 'java.lang.String',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate'
};
