// ENTITY fr.hiji.metier.ema.dto.back.EMAObjectiveResultBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMAObjectiveResultBODTO {

  idEMAObjective: number | null = 0;
  kpi: MultilingualString | null = new MultilingualString();
  unit: MultilingualString | null = new MultilingualString();
  objective: number | null = null;
  result: number | null = null;
  creationDate: Date | null = null;
  monthly: boolean | null = false;
  importFilename: string | null = '';
  ranking: number | null = 0;

  constructor(init?:Partial<EMAObjectiveResultBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.EMAObjectiveResultBODTO'] = EMAObjectiveResultBODTO;

reverseMapping[EMAObjectiveResultBODTO.name] = 'fr.hiji.metier.ema.dto.back.EMAObjectiveResultBODTO';

fields['fr.hiji.metier.ema.dto.back.EMAObjectiveResultBODTO']  = {
    idEMAObjective: 'java.lang.Integer',
    kpi: 'com.ic2.sc.MultilingualString',
    unit: 'com.ic2.sc.MultilingualString',
    objective: 'java.lang.Double',
    result: 'java.lang.Double',
    creationDate: 'java.time.Instant',
    monthly: 'java.lang.Boolean',
    importFilename: 'java.lang.String',
    ranking: 'java.lang.Integer'
};
