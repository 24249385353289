// ENTITY fr.hiji.metier.ema.entities.template.EMATemplate generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMATemplate {

  idEMATemplate: number | null = 0;
  idPopulation: number | null = 0;
  nbEMAPerMonth: MultilingualString | null = new MultilingualString();
  allowCustomOG: boolean | null = false;
  allowRemoveRepositoriesOG: boolean | null = false;
  allowObjectifEdition: boolean | null = false;
  allowObservationWithoutFirstMeeting: boolean | null = false;
  nbRepositoryMaxSelectable: number | null = 0;
  allowObsComment: boolean | null = false;
  allowExchangeSkillComment: boolean | null = false;
  allowAutoObs: boolean | null = false;
  code: string | null = '';
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<EMATemplate>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.template.EMATemplate'] = EMATemplate;

reverseMapping[EMATemplate.name] = 'fr.hiji.metier.ema.entities.template.EMATemplate';

fields['fr.hiji.metier.ema.entities.template.EMATemplate']  = {
    idEMATemplate: 'java.lang.Integer',
    idPopulation: 'java.lang.Integer',
    nbEMAPerMonth: 'com.ic2.sc.MultilingualString',
    allowCustomOG: 'java.lang.Boolean',
    allowRemoveRepositoriesOG: 'java.lang.Boolean',
    allowObjectifEdition: 'java.lang.Boolean',
    allowObservationWithoutFirstMeeting: 'java.lang.Boolean',
    nbRepositoryMaxSelectable: 'java.lang.Integer',
    allowObsComment: 'java.lang.Boolean',
    allowExchangeSkillComment: 'java.lang.Boolean',
    allowAutoObs: 'java.lang.Boolean',
    code: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
