// ENTITY fr.hiji.metier.campaign.dto.front.CampaignRepositoryAccessDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { RepositoryAccessDTO } from './RepositoryAccessDTO';

export class CampaignRepositoryAccessDTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  repositories: RepositoryAccessDTO[] | null = [];

  constructor(init?:Partial<CampaignRepositoryAccessDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.CampaignRepositoryAccessDTO'] = CampaignRepositoryAccessDTO;

reverseMapping[CampaignRepositoryAccessDTO.name] = 'fr.hiji.metier.campaign.dto.front.CampaignRepositoryAccessDTO';

fields['fr.hiji.metier.campaign.dto.front.CampaignRepositoryAccessDTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    repositories: 'java.util.List<fr.hiji.metier.campaign.dto.front.RepositoryAccessDTO>'
};
