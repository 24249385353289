// ENTITY fr.hiji.metier.fbs.dto.back.FeedbackPerAreaBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FeedbackCategoryStatBODTO } from './FeedbackCategoryStatBODTO';

export class FeedbackPerAreaBODTO {

  region: string | null = '';
  nb: number | null = 0;
  nbSolicitation: number | null = 0;
  nbSentBecauseSolicitation: number | null = 0;
  nbSentToGroupByArea: number | null = 0;
  nbSentToPersonByArea: number | null = 0;
  feedbackPerAreaPerCategory: FeedbackCategoryStatBODTO[] | null = [];

  constructor(init?:Partial<FeedbackPerAreaBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.dto.back.FeedbackPerAreaBODTO'] = FeedbackPerAreaBODTO;

reverseMapping[FeedbackPerAreaBODTO.name] = 'fr.hiji.metier.fbs.dto.back.FeedbackPerAreaBODTO';

fields['fr.hiji.metier.fbs.dto.back.FeedbackPerAreaBODTO']  = {
    region: 'java.lang.String',
    nb: 'java.lang.Integer',
    nbSolicitation: 'java.lang.Integer',
    nbSentBecauseSolicitation: 'java.lang.Integer',
    nbSentToGroupByArea: 'java.lang.Integer',
    nbSentToPersonByArea: 'java.lang.Integer',
    feedbackPerAreaPerCategory: 'java.util.List<fr.hiji.metier.fbs.dto.back.FeedbackCategoryStatBODTO>'
};
