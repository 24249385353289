// ENTITY fr.hiji.metier.reporting.dto.pdf180.ServicePercentDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ServicePercentDTO {

  serviceName: string | null = '';
  nbPersonneDansService: number | null = 0;
  percent: number | null = 0;
  percentLastWeek: number | null = 0;
  percentLastMonth: number | null = 0;

  constructor(init?:Partial<ServicePercentDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.pdf180.ServicePercentDTO'] = ServicePercentDTO;

reverseMapping[ServicePercentDTO.name] = 'fr.hiji.metier.reporting.dto.pdf180.ServicePercentDTO';

fields['fr.hiji.metier.reporting.dto.pdf180.ServicePercentDTO']  = {
    serviceName: 'java.lang.String',
    nbPersonneDansService: 'java.lang.Integer',
    percent: 'java.lang.Integer',
    percentLastWeek: 'java.lang.Integer',
    percentLastMonth: 'java.lang.Integer'
};
