// ENTITY fr.hiji.metier.campaign.entities.survey.CampaignSurveyInfos generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CampaignSurveyInfos {

  idCampaign: number | null = 0;
  withEtablissementResults: boolean | null = false;
  withRegionResults: boolean | null = false;
  withSocieteResults: boolean | null = false;
  withEnseigneResults: boolean | null = false;
  idPopulationForSurvey: number | null = 0;
  idRepositoryForSurvey: number | null = 0;
  withSurveyResultsAvailable: boolean | null = false;
  surveyResultsAvailableDate: Date | null = null;
  surveyThanksMessage: MultilingualString | null = new MultilingualString();
  idPopulationForReporting: number | null = 0;
  hideSkill: boolean | null = false;
  randomizeOrder: boolean | null = false;
  forceAnswerAllQuestions: boolean | null = false;
  surveyParticipationEmailSendOnMonday: boolean | null = false;
  surveyParticipationEmailSendOnTuesday: boolean | null = false;
  surveyParticipationEmailSendOnWednesday: boolean | null = false;
  surveyParticipationEmailSendOnThursday: boolean | null = false;
  surveyParticipationEmailSendOnFriday: boolean | null = false;
  surveyParticipationEmailSendOnSaturday: boolean | null = false;
  surveyParticipationEmailSendOnSunday: boolean | null = false;
  idPreviousCampaign: number | null = null;

  constructor(init?:Partial<CampaignSurveyInfos>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.survey.CampaignSurveyInfos'] = CampaignSurveyInfos;

reverseMapping[CampaignSurveyInfos.name] = 'fr.hiji.metier.campaign.entities.survey.CampaignSurveyInfos';

fields['fr.hiji.metier.campaign.entities.survey.CampaignSurveyInfos']  = {
    idCampaign: 'java.lang.Integer',
    withEtablissementResults: 'java.lang.Boolean',
    withRegionResults: 'java.lang.Boolean',
    withSocieteResults: 'java.lang.Boolean',
    withEnseigneResults: 'java.lang.Boolean',
    idPopulationForSurvey: 'java.lang.Integer',
    idRepositoryForSurvey: 'java.lang.Integer',
    withSurveyResultsAvailable: 'java.lang.Boolean',
    surveyResultsAvailableDate: 'java.time.LocalDate',
    surveyThanksMessage: 'com.ic2.sc.MultilingualString',
    idPopulationForReporting: 'java.lang.Integer',
    hideSkill: 'java.lang.Boolean',
    randomizeOrder: 'java.lang.Boolean',
    forceAnswerAllQuestions: 'java.lang.Boolean',
    surveyParticipationEmailSendOnMonday: 'java.lang.Boolean',
    surveyParticipationEmailSendOnTuesday: 'java.lang.Boolean',
    surveyParticipationEmailSendOnWednesday: 'java.lang.Boolean',
    surveyParticipationEmailSendOnThursday: 'java.lang.Boolean',
    surveyParticipationEmailSendOnFriday: 'java.lang.Boolean',
    surveyParticipationEmailSendOnSaturday: 'java.lang.Boolean',
    surveyParticipationEmailSendOnSunday: 'java.lang.Boolean',
    idPreviousCampaign: 'java.lang.Integer'
};
