// ENTITY fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { Organisation } from './Organisation';
import { FormationSessionStatsLiteDTO } from './FormationSessionStatsLiteDTO';
import { FormationLiteDTO } from './FormationLiteDTO';

export class FormationStatsFiltersBODTO {

  formations: FormationLiteDTO[] | null = [];
  sessions: FormationSessionStatsLiteDTO[] | null = [];
  trainers: UserFuncAvatarDTO[] | null = [];
  user: UserFuncAvatarDTO | null = null;
  organisations: Organisation[] | null = [];
  tags: string[] | null = [];
  start: Date | null = null;
  end: Date | null = null;

  constructor(init?:Partial<FormationStatsFiltersBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO'] = FormationStatsFiltersBODTO;

reverseMapping[FormationStatsFiltersBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO']  = {
    formations: 'java.util.List<fr.hiji.metier.mif.dto.front.FormationLiteDTO>',
    sessions: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.filters.FormationSessionStatsLiteDTO>',
    trainers: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    organisations: 'java.util.List<fr.hiji.metier.reseau.entities.Organisation>',
    tags: 'java.util.List<java.lang.String>',
    start: 'java.time.Instant',
    end: 'java.time.Instant'
};
