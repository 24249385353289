// ENTITY fr.hiji.metier.ema.dto.front.EMADTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { EMAUserOGDTO } from './EMAUserOGDTO';
import { Criteria } from './Criteria';
import { EMAStep } from './EMAStep';
import { EMAObservation } from './EMAObservation';
import { EMASkillMessageDTO } from './EMASkillMessageDTO';
import { EMAObservationObservableGestures } from './EMAObservationObservableGestures';
import { EMAPerception } from './EMAPerception';
import { EMANextRepository } from './EMANextRepository';
import { EMAHasRepository } from './EMAHasRepository';
import { EMARepositoryLiteDTO } from './EMARepositoryLiteDTO';
import { EMA } from './EMA';
import { EMAObjectiveDTO } from './EMAObjectiveDTO';
import { EMAUserOGCDTO } from './EMAUserOGCDTO';

export class EMADTO {

  ema: EMA | null = null;
  observableGestures: EMAUserOGDTO[] | null = [];
  observableGesturesCustom: EMAUserOGCDTO[] | null = [];
  criterias: Criteria[] | null = [];
  enabledSteps: EMAStep[] | null = [];
  observations: EMAObservation[] | null = [];
  oogs: EMAObservationObservableGestures[] | null = [];
  users: UserFuncAvatarDTO[] | null = [];
  objectives: EMAObjectiveDTO[] | null = [];
  perceptions: EMAPerception[] | null = [];
  repositories: EMARepositoryLiteDTO[] | null = [];
  currentEMARepositories: EMAHasRepository[] | null = [];
  nextEMARepositories: EMANextRepository[] | null = [];
  skillMessages: EMASkillMessageDTO[] | null = [];
  isFirstEMAWithCollab: boolean | null = false;
  isFirstEMAForCollab: boolean | null = false;
  nextEMAdate: Date | null = null;
  nbObsToDo: number | null = 0;
  allowCustomOG: boolean | null = false;
  allowRemoveRepositoriesOG: boolean | null = false;
  allowObjectifEdition: boolean | null = false;
  allowObservationWithoutFirstMeeting: boolean | null = false;
  nbEMAPerMonth: MultilingualString | null = new MultilingualString();
  nbRepositoryMaxSelectable: number | null = 0;
  allowObsComment: boolean | null = false;
  allowExchangeSkillComment: boolean | null = false;
  allowAutoObs: boolean | null = false;
  usersAvailableForNextEMA: UserFuncAvatarDTO[] | null = [];
  idUserSelectedForNextEMA: number | null = 0;

  constructor(init?:Partial<EMADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMADTO'] = EMADTO;

reverseMapping[EMADTO.name] = 'fr.hiji.metier.ema.dto.front.EMADTO';

fields['fr.hiji.metier.ema.dto.front.EMADTO']  = {
    ema: 'fr.hiji.metier.ema.entities.EMA',
    observableGestures: 'java.util.List<fr.hiji.metier.ema.dto.front.EMAUserOGDTO>',
    observableGesturesCustom: 'java.util.List<fr.hiji.metier.ema.dto.front.EMAUserOGCDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    enabledSteps: 'java.util.List<fr.hiji.metier.ema.entities.EMAStep>',
    observations: 'java.util.List<fr.hiji.metier.ema.entities.observation.EMAObservation>',
    oogs: 'java.util.List<fr.hiji.metier.ema.entities.observation.EMAObservationObservableGestures>',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    objectives: 'java.util.List<fr.hiji.metier.ema.dto.front.EMAObjectiveDTO>',
    perceptions: 'java.util.List<fr.hiji.metier.ema.entities.EMAPerception>',
    repositories: 'java.util.List<fr.hiji.metier.ema.dto.front.EMARepositoryLiteDTO>',
    currentEMARepositories: 'java.util.List<fr.hiji.metier.ema.entities.EMAHasRepository>',
    nextEMARepositories: 'java.util.List<fr.hiji.metier.ema.entities.EMANextRepository>',
    skillMessages: 'java.util.List<fr.hiji.metier.ema.dto.front.EMASkillMessageDTO>',
    isFirstEMAWithCollab: 'java.lang.Boolean',
    isFirstEMAForCollab: 'java.lang.Boolean',
    nextEMAdate: 'java.time.Instant',
    nbObsToDo: 'java.lang.Integer',
    allowCustomOG: 'java.lang.Boolean',
    allowRemoveRepositoriesOG: 'java.lang.Boolean',
    allowObjectifEdition: 'java.lang.Boolean',
    allowObservationWithoutFirstMeeting: 'java.lang.Boolean',
    nbEMAPerMonth: 'com.ic2.sc.MultilingualString',
    nbRepositoryMaxSelectable: 'java.lang.Integer',
    allowObsComment: 'java.lang.Boolean',
    allowExchangeSkillComment: 'java.lang.Boolean',
    allowAutoObs: 'java.lang.Boolean',
    usersAvailableForNextEMA: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    idUserSelectedForNextEMA: 'java.lang.Integer'
};
