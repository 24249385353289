// ENTITY fr.hiji.metier.dashboard.dto.DashboardCertificationBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class DashboardCertificationBODTO {

  idCertification: number | null = 0;
  nameCertif: MultilingualString | null = new MultilingualString();
  idCertificationSession: number | null = 0;
  nameSession: string | null = '';
  nbTarget: number | null = 0;
  nbCertif: number | null = 0;

  constructor(init?:Partial<DashboardCertificationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.dashboard.dto.DashboardCertificationBODTO'] = DashboardCertificationBODTO;

reverseMapping[DashboardCertificationBODTO.name] = 'fr.hiji.metier.dashboard.dto.DashboardCertificationBODTO';

fields['fr.hiji.metier.dashboard.dto.DashboardCertificationBODTO']  = {
    idCertification: 'java.lang.Integer',
    nameCertif: 'com.ic2.sc.MultilingualString',
    idCertificationSession: 'java.lang.Integer',
    nameSession: 'java.lang.String',
    nbTarget: 'java.lang.Integer',
    nbCertif: 'java.lang.Integer'
};
