// ENTITY fr.hiji.metier.campaign.entities.pdi.CampaignPDIFormation generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CampaignPDIFormation {

  idCampaign: number | null = 0;
  idTag: number | null = 0;
  max: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  url: string | null = '';

  constructor(init?:Partial<CampaignPDIFormation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.pdi.CampaignPDIFormation'] = CampaignPDIFormation;

reverseMapping[CampaignPDIFormation.name] = 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIFormation';

fields['fr.hiji.metier.campaign.entities.pdi.CampaignPDIFormation']  = {
    idCampaign: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    max: 'java.lang.Double',
    name: 'com.ic2.sc.MultilingualString',
    url: 'java.lang.String'
};
