// ENTITY fr.hiji.metier.certif.entities.modality.interview.ModalityInterview generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityInterview {

  idModality: number | null = 0;
  idRepository: number | null = 0;
  averageScoreToSucceed: number | null = 0;

  constructor(init?:Partial<ModalityInterview>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.interview.ModalityInterview'] = ModalityInterview;

reverseMapping[ModalityInterview.name] = 'fr.hiji.metier.certif.entities.modality.interview.ModalityInterview';

fields['fr.hiji.metier.certif.entities.modality.interview.ModalityInterview']  = {
    idModality: 'java.lang.Integer',
    idRepository: 'java.lang.Integer',
    averageScoreToSucceed: 'java.lang.Double'
};
