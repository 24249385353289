// fr.hiji.metier.mif.services.front.event.FormationEventServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationEventDTO } from '../entities/FormationEventDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationEventService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFormationEvent(idFormationEvent: number): RpcRequestBuilder<FormationEventDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventService.getFormationEvent';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationEvent, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.event.FormationEventDTO');
  }

}
