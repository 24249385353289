// fr.hiji.metier.reporting.services.back.Report180ConfigBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { Report180ConfigLiteBODTO } from '../entities/Report180ConfigLiteBODTO';
import { Report180ConfigBODTO } from '../entities/Report180ConfigBODTO';

@Injectable({
  providedIn: 'root',
})
export class Report180ConfigBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getReport180ConfigList(filter: GenericFilter): RpcRequestBuilder<ListWithCount<Report180ConfigLiteBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'Report180ConfigBOService.getReport180ConfigList';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  reportNameAlreadyExists(name: string, idReport180Config: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'Report180ConfigBOService.reportNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idReport180Config, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getReport180Config(idReport180Config: number): RpcRequestBuilder<Report180ConfigBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'Report180ConfigBOService.getReport180Config';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idReport180Config, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO');
  }

  saveReport180Config(dto: Report180ConfigBODTO): RpcRequestBuilder<Report180ConfigBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'Report180ConfigBOService.saveReport180Config';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO');
  }

}
