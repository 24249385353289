// ENTITY fr.hiji.metier.user.dto.back.UserExchangeLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class UserExchangeLiteBODTO {

  idExchange: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  campaignNames: MultilingualString[] | null = [];
  repositoryNames: MultilingualString[] | null = [];
  creationDate: Date | null = null;
  idUserManager: number | null = 0;
  idUserObserved: number | null = 0;

  constructor(init?:Partial<UserExchangeLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.UserExchangeLiteBODTO'] = UserExchangeLiteBODTO;

reverseMapping[UserExchangeLiteBODTO.name] = 'fr.hiji.metier.user.dto.back.UserExchangeLiteBODTO';

fields['fr.hiji.metier.user.dto.back.UserExchangeLiteBODTO']  = {
    idExchange: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    campaignNames: 'java.util.List<com.ic2.sc.MultilingualString>',
    repositoryNames: 'java.util.List<com.ic2.sc.MultilingualString>',
    creationDate: 'java.time.Instant',
    idUserManager: 'java.lang.Integer',
    idUserObserved: 'java.lang.Integer'
};
