// ENTITY fr.hiji.metier.campaign.dto.front.ObservationDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservationObservableGesturesDTO } from './ObservationObservableGesturesDTO';
import { Criteria } from './Criteria';

export class ObservationDTO {

  idCampaign: number | null = 0;
  campaignName: MultilingualString | null = new MultilingualString();
  campaignDescription: MultilingualString | null = new MultilingualString();
  idRepository: number | null = 0;
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDescription: MultilingualString | null = new MultilingualString();
  forceShowCampaignDescription: boolean | null = false;
  idUserObserved: number | null = 0;
  message: string | null = '';
  idLastExchange: number | null = 0;
  dateLastExchange: Date | null = null;
  conclusionFromLastExchange: string | null = '';
  idUserManagerLastExchange: number | null = 0;
  managerLastExchange: string | null = '';
  firstObservation: boolean | null = false;
  observableGestures: ObservationObservableGesturesDTO[] | null = [];
  criterias: Criteria[] | null = [];
  startAnsweringDate: Date | null = null;

  constructor(init?:Partial<ObservationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ObservationDTO'] = ObservationDTO;

reverseMapping[ObservationDTO.name] = 'fr.hiji.metier.campaign.dto.front.ObservationDTO';

fields['fr.hiji.metier.campaign.dto.front.ObservationDTO']  = {
    idCampaign: 'java.lang.Integer',
    campaignName: 'com.ic2.sc.MultilingualString',
    campaignDescription: 'com.ic2.sc.MultilingualString',
    idRepository: 'java.lang.Integer',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDescription: 'com.ic2.sc.MultilingualString',
    forceShowCampaignDescription: 'java.lang.Boolean',
    idUserObserved: 'java.lang.Integer',
    message: 'java.lang.String',
    idLastExchange: 'java.lang.Integer',
    dateLastExchange: 'java.time.Instant',
    conclusionFromLastExchange: 'java.lang.String',
    idUserManagerLastExchange: 'java.lang.Integer',
    managerLastExchange: 'java.lang.String',
    firstObservation: 'java.lang.Boolean',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservationObservableGesturesDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    startAnsweringDate: 'java.time.Instant'
};
