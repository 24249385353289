// ENTITY fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizOpenQuestionBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class CertificationQuizOpenQuestionBODTO {

  user: UserFuncAvatarDTO | null = null;
  answer: string | null = '';

  constructor(init?:Partial<CertificationQuizOpenQuestionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizOpenQuestionBODTO'] = CertificationQuizOpenQuestionBODTO;

reverseMapping[CertificationQuizOpenQuestionBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizOpenQuestionBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizOpenQuestionBODTO']  = {
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    answer: 'java.lang.String'
};
