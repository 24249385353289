// ENTITY fr.hiji.metier.referential.dto.ObservableGestureBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:35 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { RepositoryLiteDTO } from './RepositoryLiteDTO';
import { ObservableGesture } from './ObservableGesture';

export class ObservableGestureBODTO {

  observableGesture: ObservableGesture | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  criteriaName: MultilingualString | null = new MultilingualString();
  criteriaDel: boolean | null = false;
  repositories: RepositoryLiteDTO[] | null = [];
  idsTags: number[] | null = [];

  constructor(init?:Partial<ObservableGestureBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.ObservableGestureBODTO'] = ObservableGestureBODTO;

reverseMapping[ObservableGestureBODTO.name] = 'fr.hiji.metier.referential.dto.ObservableGestureBODTO';

fields['fr.hiji.metier.referential.dto.ObservableGestureBODTO']  = {
    observableGesture: 'fr.hiji.metier.referential.entities.ObservableGesture',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    criteriaName: 'com.ic2.sc.MultilingualString',
    criteriaDel: 'java.lang.Boolean',
    repositories: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryLiteDTO>',
    idsTags: 'java.util.List<java.lang.Integer>'
};
