// ENTITY fr.hiji.metier.ema.dto.front.EMAOGHistogramDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAOGHistogramDTO {

  idEMA: number | null = 0;
  creationDate: Date | null = null;
  avgObservation: number | null = 0;
  countObservation: number | null = 0;
  avgAutoObservation: number | null = 0;
  countAutoObservation: number | null = 0;
  avgObservationCoach: number | null = 0;
  countObservationCoach: number | null = 0;

  constructor(init?:Partial<EMAOGHistogramDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAOGHistogramDTO'] = EMAOGHistogramDTO;

reverseMapping[EMAOGHistogramDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAOGHistogramDTO';

fields['fr.hiji.metier.ema.dto.front.EMAOGHistogramDTO']  = {
    idEMA: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    avgObservation: 'java.lang.Double',
    countObservation: 'java.lang.Integer',
    avgAutoObservation: 'java.lang.Double',
    countAutoObservation: 'java.lang.Integer',
    avgObservationCoach: 'java.lang.Double',
    countObservationCoach: 'java.lang.Integer'
};
