// ENTITY fr.hiji.metier.mif.entities.event.FormationEventTag generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventTag {

  idFormationEvent: number | null = 0;
  tag: string | null = '';

  constructor(init?:Partial<FormationEventTag>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.FormationEventTag'] = FormationEventTag;

reverseMapping[FormationEventTag.name] = 'fr.hiji.metier.mif.entities.event.FormationEventTag';

fields['fr.hiji.metier.mif.entities.event.FormationEventTag']  = {
    idFormationEvent: 'java.lang.Integer',
    tag: 'java.lang.String'
};
