// fr.hiji.metier.campaign.services.back.survey.SurveyParticipationReportingUserBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { SurveyParticipationReportingUserBODTO } from '../entities/SurveyParticipationReportingUserBODTO';

@Injectable({
  providedIn: 'root',
})
export class SurveyParticipationReportingUserBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getSurveyParticipationReporting(genericFilter: GenericFilter, idSurvey: number, idPopulationForReporting: number): RpcRequestBuilder<ListWithCount<SurveyParticipationReportingUserBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyParticipationReportingUserBOService.getSurveyParticipationReporting';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idSurvey, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idPopulationForReporting, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
