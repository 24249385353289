// ENTITY fr.hiji.metier.campaign.dto.back._360.AssessorTeamsHHEGDTO generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class AssessorTeamsHHEGDTO {

  assessor: UserFuncAvatarDTO | null = null;
  members: UserFuncAvatarDTO[] | null = [];

  constructor(init?:Partial<AssessorTeamsHHEGDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back._360.AssessorTeamsHHEGDTO'] = AssessorTeamsHHEGDTO;

reverseMapping[AssessorTeamsHHEGDTO.name] = 'fr.hiji.metier.campaign.dto.back._360.AssessorTeamsHHEGDTO';

fields['fr.hiji.metier.campaign.dto.back._360.AssessorTeamsHHEGDTO']  = {
    assessor: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    members: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>'
};
