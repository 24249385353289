// ENTITY fr.hiji.metier.ema.entities.EMAPerception generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:36 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAPerceptionType } from './EMAPerceptionType';

export class EMAPerception {

  idEMA: number | null = 0;
  type: EMAPerceptionType | null = null;
  text: string | null = '';

  constructor(init?:Partial<EMAPerception>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMAPerception'] = EMAPerception;

reverseMapping[EMAPerception.name] = 'fr.hiji.metier.ema.entities.EMAPerception';

fields['fr.hiji.metier.ema.entities.EMAPerception']  = {
    idEMA: 'java.lang.Integer',
    type: 'fr.hiji.metier.ema.entities.EMAPerceptionType',
    text: 'java.lang.String'
};
