// fr.hiji.metier.user.services.common.RefreshSessionServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class RefreshSessionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  refreshSession(): RpcRequestBuilder<Date> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RefreshSessionService.refreshSession';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.time.Instant');
  }

}
