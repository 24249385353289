// fr.hiji.metier.campaign.services.back.InquiryBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Oct 09 09:33:27 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { InquiryDTO } from '../entities/InquiryDTO';
import { InquiryTargetType } from '../entities/InquiryTargetType';

@Injectable({
  providedIn: 'root',
})
export class InquiryBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getInquiry(idInquiry: number): RpcRequestBuilder<InquiryDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryBOService.getInquiry';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front._360.InquiryDTO');
  }

  deleteInquiry(idInquiry: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryBOService.deleteInquiry';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  reactivateInquiry(idInquiry: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryBOService.reactivateInquiry';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateTargetType(idInquiry: number, idUser: number, type: InquiryTargetType): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryBOService.updateTargetType';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(type, 'fr.hiji.metier.campaign.entities._360.InquiryTargetType', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  delete360InquiryTarget(idUser: number, idInquiry: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'InquiryBOService.delete360InquiryTarget';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idInquiry, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
